.nunito-font {
  font-family: "Nunito", sans-serif;
}

.bg-input {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.37);
}

.contact-container {
  @apply flex flex-row  text-white nunito-font w-[80%] mx-auto my-auto mt-20 mb-16 overflow-hidden;
}

.contact-text {
  @apply flex-[40%] ml-10 pt-3;
}

.contact-text > h1 {
  @apply text-[40px] text-white mb-12;
}

.contact-text > p {
  @apply w-[80%] text-[17px] font-light text-slate-400;
}

.contact-icons {
  @apply flex flex-row space-x-9 mt-12 text-[40px];
}

.contact-icons {
  @apply cursor-pointer transition transform ease-in-out duration-700;
}

.mail {
  @apply text-[50px] hover:text-red-700 transition transform ease-in-out duration-500;
}

.linked {
  @apply rounded-[3px] hover:bg-white hover:text-cyan-800 mb-2 mt-1 transition transform ease-in-out duration-700;
}

.github {
  @apply hover:bg-zinc-900 rounded-full mb-2 mt-1 transition transform ease-in-out duration-500;
}

.contact-form {
  @apply space-y-10 w-[40%] mr-10 pt-3;
}

.input-con-1 {
  @apply flex flex-row space-x-6;
}

.input-div {
  @apply flex flex-col relative;
}

.input-label {
  @apply font-extralight text-slate-300 tracking-wide text-opacity-80 absolute left-4 top-4 px-1 transition duration-200;
}

.input {
  @apply bg-input border border-[#2a2b3c] text-[16px] p-[15px] rounded-[7px] font-light tracking-wide outline-none focus:border-2 focus:border-[#262941] placeholder-gray-300 placeholder-opacity-0 transition duration-300;
}

.input:focus ~ .input-label,
.input:not(:placeholder-shown) ~ .input-label {
  @apply font-normal text-slate-200 bg-[#262941] px-2 rounded-[7px];
  transform: translateY(-1.75rem);
  transition: color 0.2s, transform 0.5s;
}

.submit-btn {
  @apply flex flex-row space-x-1 text-[17px] font-light bg-[#262941] p-3 rounded-[7px] transition transform ease-in-out duration-300;
}

.send {
  @apply text-[23px] pt-1 transition transform ease-in-out duration-300;
}

.submit-btn:hover {
  @apply scale-95;
}

.submit-btn:hover .send {
  @apply rotate-45;
}

@media (max-width: 1280px) {
  .contact-container {
    @apply w-[85%];
  }

  .contact-text {
    @apply flex-[50%] ml-5 pt-3;
  }

  .contact-text > h1 {
    @apply text-[35px] mb-10;
  }

  .contact-text > p {
    @apply w-[80%] text-[14px];
  }

  .contact-icons {
    @apply space-x-7 mt-10 text-[30px];
  }

  .mail {
    @apply text-[38px];
  }

  .contact-form {
    @apply space-y-7 w-[40%] mr-0 pt-3;
  }

  .input-label {
    @apply text-[14px] left-3 top-3;
  }

  .input {
    @apply text-[15px] p-[10px] rounded-[5px];
  }

  .input:focus ~ .input-label,
  .input:not(:placeholder-shown) ~ .input-label {
    @apply rounded-[5px];
    transform: translateY(-1.45rem);
  }

  .submit-btn {
    @apply text-[13px] p-3 rounded-[5px];
  }

  .send {
    @apply text-[19px] pt-1;
  }
}

@media (max-width: 1024px) {
  .contact-container {
    @apply w-[100%] ml-4 mr-4 mt-16 mb-16;
  }

  .contact-text {
    @apply flex-[50%] ml-0;
  }

  .contact-text > h1 {
    @apply text-[25px] mb-8;
  }

  .contact-text > p {
    @apply w-[87%] text-[13px];
  }

  .contact-icons {
    @apply space-x-6 mt-10 text-[27px];
  }

  .mail {
    @apply text-[33px] pt-[2px];
  }

  .contact-form {
    @apply space-y-7 w-[50%] pr-10;
  }

  .input-label {
    @apply left-3 top-[10px] text-[14px] px-1;
  }

  .input {
    @apply text-[14px] p-[10px] rounded-[5px];
  }

  .input:focus ~ .input-label,
  .input:not(:placeholder-shown) ~ .input-label {
    @apply rounded-[3px];
    transform: translateY(-1.45rem);
  }
}

@media (max-width: 768px) {
  .contact-container {
    @apply flex flex-col w-full justify-center items-center px-5 ml-0 mr-0;
  }

  .contact-text {
    @apply w-[80%] ml-0 mr-0 mx-0 text-center;
  }

  .contact-text > h1 {
    @apply text-[30px] mb-7;
  }

  .contact-text > p {
    @apply w-[100%] text-[12px] text-center;
  }

  .contact-icons {
    @apply space-x-5 mt-5 mb-5 text-[24px] flex justify-center items-center;
  }

  .mail {
    @apply text-[27px] pt-0;
  }

  .contact-form {
    @apply space-y-7 w-[80%] pr-0 mr-0;
  }

  .input-label {
    @apply left-2 top-2 px-1 transition duration-200 text-[13px];
  }

  .input {
    @apply text-[12px] p-[8px] rounded-[5px];
  }

  .input:focus ~ .input-label,
  .input:not(:placeholder-shown) ~ .input-label {
    @apply rounded-[3px];
    transform: translateY(-1.35rem);
  }

  .submit-btn {
    @apply text-[11px] p-2 rounded-[5px];
  }

  .send {
    @apply text-[15px] pt-1;
  }
}

@media (max-width: 480px) {
  .contact-text {
    @apply w-[100%] ml-0 mx-0 text-center;
  }

  .contact-text > h1 {
    @apply text-[27px] mb-7;
  }

  .contact-icons {
    @apply space-x-5 mt-5 mb-5 text-[20px] flex justify-center items-center;
  }

  .mail {
    @apply text-[25px] pt-[2px];
  }

  .contact-form {
    @apply space-y-7 w-[100%] mr-0;
  }
}

@media (max-width: 320px) {
  .contact-container {
    @apply flex flex-col w-full;
  }

  .contact-text {
    @apply w-[100%] mx-0 text-center;
  }

  .contact-text > h1 {
    @apply text-[25px] mb-7;
  }

  .contact-text > p {
    @apply w-[100%] text-[12px] text-justify;
  }

  .contact-icons {
    @apply space-x-5 mt-5 mb-5 text-[20px];
  }

  .mail {
    @apply text-[25px] -mt-1;
  }

  .contact-form {
    @apply space-y-7 w-[100%] mr-0;
  }

  .input-label {
    @apply left-2 top-2 px-1 transition duration-200 text-[13px];
  }

  .input {
    @apply text-[12px] p-[8px] rounded-[5px];
  }

  .input:focus ~ .input-label,
  .input:not(:placeholder-shown) ~ .input-label {
    @apply rounded-[3px];
    transform: translateY(-1.35rem);
  }

  .submit-btn {
    @apply text-[10px] p-2 rounded-[5px];
  }

  .send {
    @apply text-[14px] pt-1;
  }
}
