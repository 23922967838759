.nunito-font {
  font-family: "Nunito", sans-serif;
}

.nav {
  @apply flex justify-center text-white mt-3;
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; */
}

.nav-container {
  @apply flex w-[70%] justify-center p-4 rounded-[7px];
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.04),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.37);
}

.nav-left {
  @apply w-[10%] nunito-font font-light text-[18px] cursor-pointer;
}

.nav-center {
  @apply flex justify-center w-[60%] space-x-[7%] font-light tracking-wider;
}

.hover-effect {
  @apply relative;
}

.hover-effect::after {
  @apply content-[''] bg-white h-[1px] left-0 bottom-0 w-[0%] font-normal absolute duration-500;
}

.hover-effect:hover::after {
  @apply w-[100%] text-white;
}

.hover-effect.active {
  @apply font-normal text-white;
}

.hover-effect.active::after {
  @apply w-[100%];
}

.nav-right {
  @apply w-[30%] flex justify-end space-x-7;
}

.nav-socials {
  @apply text-[27px] hover:translate-y-[-5px] transition transform duration-300;
}

.git {
  @apply bg-zinc-800 rounded-full;
}

.linkedin {
  @apply text-cyan-700 bg-white rounded-[2px];
}

.nav-connect {
  @apply flex flex-row bg-white text-[#25273A] p-2 -mt-2 -mb-1 rounded-[7px] cursor-pointer transition transform ease-in-out duration-300;
}

.nav-connect > p {
  @apply font-medium pr-2;
}

.nav-connect > span {
  @apply text-[20px] pt-1 transition transform ease-in-out duration-300;
}

.nav-connect:hover {
  @apply scale-90;
}

.nav-connect:hover > span {
  @apply rotate-45;
}

.menu-icon {
  @apply hidden;
}

@media (max-width: 1600px) {
  .nav-center {
    @apply w-[50%] space-x-[5%] text-[14px] pt-1;
  }

  .nav-right {
    @apply w-[40%];
  }

  .nav-socials {
    @apply text-[27px] mb-1;
  }

  .menu-icon {
    @apply hidden;
  }
}

@media (max-width: 1200px) {
  .nav-container {
    @apply flex justify-start w-[100%] p-2;
  }

  .nav-socials {
    @apply text-[27px] mb-1;
  }

  .menu-icon {
    @apply hidden;
  }
}

@media (max-width: 1024px) {
  .nav-container {
    @apply flex justify-start w-[100%] p-2;
  }

  .nav-center {
    @apply w-[60%] space-x-[5%] text-[14px] pt-1;
  }

  .nav-right {
    @apply space-x-5;
  }

  .nav-socials {
    @apply text-[25px] mt-1;
  }

  .nav-connect {
    @apply rounded-[5px] p-[6px] -mt-1;
  }

  .nav-connect > p {
    @apply text-[13px] pr-1;
  }

  .nav-connect > span {
    @apply text-[15px];
  }

  .menu-icon {
    @apply hidden;
  }
}

@media (max-width: 768px) {
  .nav {
    @apply mt-2;
  }

  .nav-container {
    @apply flex justify-start w-[100%] p-2;
  }

  .nav-left {
    @apply w-[20%];
  }

  .nav-center {
    @apply hidden;
  }

  .nav-right {
    @apply w-[80%] space-x-5;
  }

  .nav-socials {
    @apply text-[23px] mt-1;
  }

  .nav-connect {
    @apply rounded-[5px] p-[6px] -mt-1;
  }

  .nav-connect > p {
    @apply text-[13px] pr-1;
  }

  .nav-connect > span {
    @apply text-[15px];
  }

  .menu-icon {
    @apply block pt-1 text-[23px];
  }
}

@media (max-width: 480px) {
  .nav {
    @apply mt-2;
  }

  .nav-container {
    @apply flex justify-start w-[100%] p-2;
  }

  .nav-left {
    @apply w-[20%];
  }

  .nav-center {
    @apply hidden;
  }

  /* .nav-right {
    @apply hidden;
  } */

  .menu-icon {
    @apply block pt-1 text-[23px];
  }

  .nav-socials {
    @apply hidden;
  }

  .nav-connect {
    @apply hidden;
  }
}
