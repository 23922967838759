.nunito-font {
  font-family: "Nunito", sans-serif;
}

.about-container {
  @apply flex flex-col justify-center items-center mb-0;
}

.about-wrapper {
  @apply flex flex-col justify-center w-[75%];
}

.about-header {
  @apply nunito-font flex justify-center text-[40px] mb-10 mt-10;
}

.about-grid-container {
  @apply grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 15px;
  /* Center items within the grid container */
  place-items: center;
}

.box-bg {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.37);
}

.experience-container {
  @apply box-bg h-[520px] w-full rounded-l-[10px] nunito-font p-4 space-y-2;
  grid-area: 1 / 1 / 5 / 3;
}

.experience-container > h1 {
  @apply text-[20px];
}

.experience {
  @apply bg-[#2d304b] p-3 rounded-[7px];
}

.experience-head {
  @apply flex text-[13px] font-light;
}

.company {
  @apply w-[50%];
}

.date {
  @apply w-[50%] flex justify-end;
}

.experience-desc > h1 {
  @apply text-[17px] font-normal tracking-wide py-2;
}

.experience-desc > p {
  @apply text-[13px] font-light tracking-wide text-justify;
}

.experience-tools {
  @apply flex flex-wrap space-x-3 border-t border-[#3f4578] mt-2;
}

.tool-wrapper {
  @apply flex flex-row space-x-1;
}

.tool-title {
  @apply pt-2 pb-1 text-[12px];
}

.icon-1 {
  @apply w-6;
}

.icon-2 {
  @apply w-4;
}

.icon-3 {
  @apply w-16;
}

.me-container {
  @apply box-bg h-[245px] w-[100%] flex flex-row space-x-4  rounded-tr-[10px];
  grid-area: 1 / 3 / 3 / 7;
}

.my-img-container {
  @apply w-[25%] flex flex-col bg-[url(/public/images/me.jpg)] bg-cover bg-no-repeat rounded-[5px] m-2;
}

.my-content-container {
  @apply w-[70%] nunito-font;
}

.my-content-container > h1 {
  @apply text-[40px] pt-10 pb-2;
}

.my-content-container > p {
  @apply text-[15px] mr-10 text-justify font-light;
}

.about-btns {
  @apply flex justify-end space-x-6 mr-10 mt-2 font-light;
}

.about-cv {
  @apply border-b pb-1 hover:text-[#a9b0f8] hover:border-b-[#a9b0f8] hover:scale-95 transition duration-300;
}

.github-container {
  @apply h-[245px] w-[100%] space-y-1;
  grid-area: 3 / 3 / 5 / 5;
}

.socials-top {
  @apply h-[50%] w-full bg-[url(/public/images/github.png)] bg-cover bg-center bg-no-repeat hover:scale-95 transition duration-500 cursor-pointer;
}

.socials-bottom {
  @apply h-[50%] w-full bg-[url(/public/images/LinkedIn-page.jpg)] bg-cover bg-center bg-no-repeat hover:scale-95 transition duration-500 cursor-pointer;
}

.education-container {
  @apply box-bg h-[245px] w-[100%] rounded-br-[10px];
  grid-area: 3 / 5 / 5 / 7;
}

.services-top {
  @apply h-[50%] w-full bg-[url(/public/images/dev.jpg)] bg-center bg-cover bg-no-repeat;
}

.services-bottom {
  @apply p-2 px-5;
}

.services-bottom > h1 {
  @apply text-[17px] font-sans;
}

.services-sub {
  @apply flex flex-row space-x-7;
}

.service-img {
  @apply w-[70px] pl-3;
}

.services {
  @apply font-light tracking-wide;
}

@media (max-width: 1535px) {
  .about-wrapper {
    @apply w-[90%];
  }
}

@media (max-width: 1440px) {
  .about-wrapper {
    @apply w-[80%];
  }

  .about-header {
    @apply text-[35px] mb-7 mt-10;
  }

  .about-grid-container {
    grid-column-gap: 10px;
    grid-row-gap: 0px;
  }

  .experience-desc > p {
    @apply text-[12px] pb-2;
  }

  .experience-tools {
    @apply space-x-2;
  }

  .tool-title {
    @apply pt-2 pb-1 text-[10px];
  }

  .icon-1 {
    @apply w-5;
  }

  .icon-2 {
    @apply w-3;
  }

  .icon-3 {
    @apply w-12;
  }
}

@media (max-width: 1280px) {
  .about-wrapper {
    @apply w-[100%];
  }

  .about-header {
    @apply text-[35px] mb-7 mt-10;
  }

  .about-grid-container {
    grid-column-gap: 10px;
    grid-row-gap: 0px;
  }

  .experience-desc > p {
    @apply text-[12px] pb-2;
  }

  .experience-tools {
    @apply space-x-2;
  }

  .tool-title {
    @apply pt-2 pb-1 text-[10px];
  }

  .icon-1 {
    @apply w-5;
  }

  .icon-2 {
    @apply w-3;
  }

  .icon-3 {
    @apply w-12;
  }

  .about-cv {
    @apply text-[13px];
  }
}

@media (max-width: 1024px) {
  .about-wrapper {
    @apply w-[100%];
  }

  .about-header {
    @apply text-[35px] mb-7 mt-10;
  }

  .about-grid-container {
    grid-column-gap: 10px;
    grid-row-gap: 0px;
  }

  .experience-container {
    @apply rounded-l-[7px] p-2 space-y-2;
    grid-area: 1 / 1 / 5 / 3;
  }

  .experience-container > h1 {
    @apply text-[17px] pb-1;
  }

  .experience-head {
    @apply flex text-[10px] font-light pb-1;
  }

  .experience-desc > h1 {
    @apply text-[13px] pb-2;
  }

  .experience-desc > p {
    @apply text-[10px] pb-2;
  }

  .experience-tools {
    @apply space-x-2;
  }

  .tool-title {
    @apply pt-2 pb-1 text-[10px];
  }

  .icon-1 {
    @apply w-5;
  }

  .icon-2 {
    @apply w-3;
  }

  .icon-3 {
    @apply w-12;
  }

  .me-container {
    @apply h-[260px] rounded-tr-[7px];
  }

  .my-img-container {
    @apply w-[50%];
  }

  .my-content-container > h1 {
    @apply text-[25px] pt-10 pb-2;
  }

  .my-content-container > p {
    @apply text-[12px] mr-6 text-justify font-extralight;
  }

  .about-btns {
    @apply space-x-4 mr-7 mt-5 text-[12px];
  }

  .services-bottom {
    @apply p-2 px-5;
  }

  .services-bottom > h1 {
    @apply text-[16px];
  }

  .services-sub {
    @apply flex flex-row space-x-5;
  }

  .service-img {
    @apply w-[50px] h-[70px] pl-0;
  }

  .services {
    @apply font-light text-[11px] pt-2 space-y-1 tracking-wide;
  }
}

@media (max-width: 768px) {
  .about-wrapper {
    @apply w-[100%];
  }

  .about-header {
    @apply text-[30px] -mb-8 mt-10;
  }

  .about-grid-container {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    place-items: center;
  }

  .me-container {
    @apply h-[230px] w-[100%] flex flex-row items-center space-x-0 rounded-t-[7px];
    grid-area: 1 / 1 / 3 / 7;
  }

  .my-img-container {
    @apply w-[40%] h-[200px] flex flex-col;
  }

  .my-content-container {
    @apply w-[100%] flex flex-col items-start pl-4;
  }

  .my-content-container > h1 {
    @apply text-[20px] pt-0 pb-2 pr-4;
  }

  .my-content-container > p {
    @apply text-[13px] mx-0 mr-8 tracking-wide font-extralight;
  }

  .about-btns {
    @apply space-x-7 mr-5 mt-5 font-light text-[12px];
  }

  .experience-container {
    @apply box-bg -mt-9 h-[370px] w-full rounded-l-[0px] p-2 space-y-2;
    grid-area: 3 / 1 / 5 / 7;
  }

  .experience-container > h1 {
    @apply text-[20px] text-center;
  }

  .experience {
    @apply rounded-[5px];
  }

  .experience-head {
    @apply text-[13px] pb-0 tracking-wider font-extralight;
  }

  .experience-desc > h1 {
    @apply text-[17px] pb-0 font-light tracking-wide py-2;
  }

  .experience-desc > p {
    @apply text-[12px] pb-0 font-extralight tracking-wider;
  }

  .experience-tools {
    @apply space-x-3;
  }

  .tool-wrapper {
    @apply flex flex-row space-x-1;
  }

  .tool-title {
    @apply pt-1 pb-0 text-[12px];
  }

  .icon-1 {
    @apply w-4;
  }

  .icon-2 {
    @apply w-3;
  }

  .icon-3 {
    @apply w-12;
  }

  .education-container {
    @apply mt-4 h-[245px] w-[100%] rounded-bl-[5px];
    grid-area: 5 / 1 / 7 / 5;
  }

  .services-bottom {
    @apply p-2 px-5;
  }

  .services-bottom > h1 {
    @apply text-[15px] tracking-wide;
  }

  .services-sub {
    @apply space-x-10;
  }

  .service-img {
    @apply w-[55px] h-[80px] pl-0 pt-2;
  }

  .services {
    @apply font-light tracking-wide text-[13px] pt-2 space-y-1;
  }

  .github-container {
    @apply h-[245px] w-[100%] pl-2 pt-2 space-y-2;
    grid-area: 5 / 5 / 7 / 7;
  }

  .socials-bottom {
    @apply bg-left rounded-br-[5px];
  }
}

@media (max-width: 615px) {
  .experience-container {
    @apply h-[410px] -mt-[49px];
  }
}

@media (max-width: 530px) {
  .experience-container {
    @apply h-[430px] -mt-[52px];
  }

  .experience-tool {
    @apply space-x-3;
  }
}

@media (max-width: 480px) {
  .about-wrapper {
    @apply w-[100%];
  }

  .about-header {
    @apply text-[30px] -mb-0 mt-10;
  }

  .about-grid-container {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    place-items: center;
  }

  .me-container {
    @apply h-[390px] w-[100%] flex flex-col items-center space-y-4 rounded-t-[7px];
    grid-area: 1 / 1 / 3 / 7;
  }

  .my-img-container {
    @apply w-[40%] h-[180px] flex flex-col;
  }

  .my-content-container {
    @apply w-[100%] flex flex-col items-center;
  }

  .my-content-container > h1 {
    @apply text-[18px] pt-0 pb-2 pr-4;
  }

  .my-content-container > p {
    @apply text-[12px] mx-4 mr-8 tracking-wider font-extralight;
  }

  .about-btns {
    @apply space-x-7 mr-5 mt-5 font-light text-[11px];
  }

  .experience-container {
    @apply box-bg -mt-2 h-[450px] w-full rounded-l-[0px] p-2 space-y-2;
    grid-area: 3 / 1 / 5 / 7;
  }

  .experience-container > h1 {
    @apply text-[18px] text-center;
  }

  .experience {
    @apply rounded-[5px];
  }

  .experience-head {
    @apply text-[12px] pb-0 tracking-wider font-extralight;
  }

  .experience-desc > h1 {
    @apply text-[15px] pb-0 font-light tracking-wide py-2;
  }

  .experience-desc > p {
    @apply text-[11px] pb-0 font-extralight tracking-wider;
  }

  .experience-tools {
    @apply space-x-2;
  }

  .tool-wrapper {
    @apply flex flex-row space-x-1;
  }

  .tool-title {
    @apply pt-3 pb-2 text-[10px];
  }

  .icon-1 {
    @apply w-4;
  }

  .icon-2 {
    @apply w-3;
  }

  .icon-3 {
    @apply w-12;
  }

  .education-container {
    @apply mt-4 h-[245px] w-[100%] rounded-b-[7px];
    grid-area: 5 / 1 / 7 / 7;
  }

  .services-bottom {
    @apply p-2 px-5;
  }

  .services-bottom > h1 {
    @apply text-[15px] tracking-wide;
  }

  .services-sub {
    @apply space-x-10;
  }

  .service-img {
    @apply w-[55px] h-[80px] pl-0 pt-2;
  }

  .services {
    @apply font-light tracking-wide text-[13px] pt-2 space-y-1;
  }

  .github-container {
    @apply hidden;
  }
}

@media (max-width: 380px) {
  .experience-container {
    @apply box-bg -mt-4 h-[500px] w-full rounded-l-[0px] p-2 space-y-2;
    grid-area: 3 / 1 / 5 / 7;
  }
}

@media (max-width: 320px) {
  .about-wrapper {
    @apply w-[100%];
  }

  .about-header {
    @apply text-[30px] -mb-10 mt-10;
  }

  .about-grid-container {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    place-items: center;
  }

  .me-container {
    @apply h-[350px] w-[100%] flex flex-col items-center space-y-4 rounded-t-[7px];
    grid-area: 1 / 1 / 3 / 7;
  }

  .my-img-container {
    @apply w-[40%] h-[125px] flex flex-col;
  }

  .my-content-container {
    @apply w-[100%] flex flex-col items-center;
  }

  .my-content-container > h1 {
    @apply text-[17px] pt-0 pb-2 pr-4;
  }

  .my-content-container > p {
    @apply text-[11px] mx-0 mr-4 tracking-wider font-extralight;
  }

  .about-btns {
    @apply space-x-7 mr-5 mt-5 font-light text-[11px];
  }

  .experience-container {
    @apply box-bg -mt-10 h-[510px] w-full rounded-l-[0px] p-2 space-y-2;
    grid-area: 3 / 1 / 5 / 7;
  }

  .experience-container > h1 {
    @apply text-[17px] text-center;
  }

  .experience {
    @apply rounded-[5px];
  }

  .experience-head {
    @apply text-[11px] pb-0 tracking-wider font-extralight;
  }

  .experience-desc > h1 {
    @apply text-[14px] pb-0 font-light tracking-wide py-2;
  }

  .experience-desc > p {
    @apply text-[10px] pb-0 font-extralight tracking-wider;
  }

  .experience-tools {
    @apply space-x-2;
  }

  .tool-wrapper {
    @apply flex flex-row space-x-1;
  }

  .tool-title {
    @apply pt-3 pb-2 text-[10px];
  }

  .icon-1 {
    @apply w-4;
  }

  .icon-2 {
    @apply w-3;
  }

  .icon-3 {
    @apply w-12;
  }

  .education-container {
    @apply mt-4 h-[245px] w-[100%] rounded-b-[7px];
    grid-area: 5 / 1 / 7 / 7;
  }

  .services-bottom {
    @apply p-2 px-5;
  }

  .services-bottom > h1 {
    @apply text-[15px] tracking-wide;
  }

  .services-sub {
    @apply space-x-7;
  }

  .service-img {
    @apply w-[55px] h-[80px] pl-0 pt-2;
  }

  .services {
    @apply font-light tracking-wide text-[12px] pt-2 space-y-1;
  }

  .github-container {
    @apply hidden;
  }
}
