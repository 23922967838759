@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

.nunito-font {
  font-family: "Nunito", sans-serif;
}

.downloadBtn {
  @apply relative w-[150px] h-[45px] nunito-font border border-[#1f212f] rounded-[7px] cursor-pointer overflow-hidden;
  text-decoration: none;
  transition: 0.8s;
  transition-delay: 0.5s;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.04),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.37);
}

.downloadBtn .fas {
  @apply mr-[8px];
}

.download-icon {
  @apply pt-1 text-[20px];
}

.downloadBtn .download {
  @apply absolute top-[50%] left-[50%] whitespace-nowrap;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.downloadBtn.active .download {
  transform: translate(-50%, 60px);
  animation: bottom_top 0.5s linear forwards;
  animation-delay: 12s;
}

.downloadBtn .download-completed {
  @apply absolute top-0 left-[50%] text-white whitespace-nowrap;
  transform: translate(-50%, -60px);
}

.downloadBtn.active .download-completed {
  animation: top_bottom 1.3s linear forwards;
  animation-delay: 6.5s;
}

.downloadBtn.active {
  @apply w-[350px] h-[5px];
  animation: btn_width 4.5s linear forwards;
  animation-delay: 5s;
}

.downloadBtn.active::before {
  @apply absolute top-0 left-0 w-0 h-[100%] bg-[#6e77c6];
  content: "";
  animation: before_active 10s linear forwards;
  animation-delay: 1.5s;
}

.downloadBtn.active::after {
  @apply absolute top-[50%] left-[50%] text-[17px] text-[#222];
  content: "\2713";
  font-weight: 900;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.3s;
  animation: check_scale 2s linear forwards;
  animation-delay: 8.5s;
}

@keyframes before_active {
  0% {
    width: 0%;
    height: 100%;
  }
  30%,
  95% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 100%;
    height: 0%;
  }
}
@keyframes btn_width {
  0% {
    width: 350px;
    height: 5px;
  }
  20%,
  70% {
    width: 150px;
    height: 45px;
  }
  80%,
  90% {
    width: 50px;
    height: 50px;
  }
  100% {
    width: 150px;
    height: 45px;
  }
}
@keyframes top_bottom {
  0% {
    transform: translate(-50%, -60px);
  }
  20%,
  90% {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 120%;
    transform: translate(-50%, -50%);
  }
}
@keyframes bottom_top {
  0% {
    transform: translate(-50%, 60px);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes check_scale {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  20%,
  90% {
    transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, 100px) scale(1.5);
  }
}

@media (max-width: 1440px) {
  .downloadBtn {
    @apply w-[140px] h-[40px] rounded-[5px] text-[14px];
  }

  .downloadBtn .fas {
    @apply mr-[5px];
  }

  .download-icon {
    @apply pt-1 text-[17px];
  }

  .downloadBtn.active {
    @apply w-[350px] h-[3px];
  }

  @keyframes btn_width {
    0% {
      width: 350px;
      height: 3px;
    }
    20%,
    70% {
      width: 140px;
      height: 40px;
    }
    80%,
    90% {
      width: 50px;
      height: 50px;
    }
    100% {
      width: 140px;
      height: 40px;
    }
  }
}

@media (max-width: 480px) {
  .downloadBtn {
    @apply w-[125px] h-[35px] rounded-[5px] text-[13px];
  }

  @keyframes btn_width {
    0% {
      width: 350px;
      height: 3px;
    }
    20%,
    70% {
      width: 125px;
      height: 35px;
    }
    80%,
    90% {
      width: 50px;
      height: 50px;
    }
    100% {
      width: 125px;
      height: 35px;
    }
  }
}
