@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

.nunito-font {
  font-family: "Nunito", sans-serif;
}

.home-container {
  @apply p-0 m-0;
}

.home-center {
  @apply p-10;
}

.home-intro {
  @apply text-white;
}

.home-status {
  @apply flex flex-row justify-center nunito-font font-thin m-3;
}

.home-status-container {
  @apply flex flex-row space-x-3 p-2 px-10 rounded-[7px] border border-[#2f3143];
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.04),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.37);
}

.home-status-circle {
  @apply bg-green-500 rounded-full w-[10px] h-[10px] mt-[6px];
  box-shadow: 2px 2px 10px #00e053, -2px -2px 10px #00ff69;
}

.home-intro-name {
  @apply nunito-font flex justify-center text-[60px];
}

.home-intro-head {
  @apply nunito-font flex justify-center text-[60px];
}

.home-intro-sub {
  @apply flex justify-center text-center py-4 font-normal tracking-wide text-slate-500;
}

.download-cv {
  @apply flex justify-center mt-7 h-12;
}

.home-marquee {
  @apply flex justify-center items-center w-full mt-16;
}

.home-marquee-container {
  @apply w-[50%] overflow-hidden;
}

@media (max-width: 1440px) {
  .home-center {
    @apply p-2 mt-5;
  }

  .home-status {
    @apply m-1 mt-5;
  }

  .home-status-container {
    @apply space-x-2 p-[7px] px-5 rounded-[5px] text-[14px];
  }

  .home-status-circle {
    @apply w-[6px] h-[6px] mt-[8px];
    box-shadow: 1px 1px 7px #00e053, -1px -1px 7px #00ff69;
  }

  .home-intro-name {
    @apply text-[45px] mt-7;
  }

  .home-intro-head {
    @apply text-[45px];
  }

  .home-intro-sub {
    @apply text-[13px] text-center py-7;
  }

  .download-cv {
    @apply flex justify-center mt-7 h-12;
  }

  .home-marquee {
    @apply mt-12;
  }

  .home-marquee-container {
    @apply w-[80%];
  }
}

@media (max-width: 1024px) {
  .home-status-container {
    @apply space-x-2 p-[7px] px-5 rounded-[5px] text-[13px];
  }

  .home-status-circle {
    @apply w-[6px] h-[6px] mt-[7px];
    box-shadow: 1px 1px 7px #00e053, -1px -1px 7px #00ff69;
  }

  .home-intro-name {
    @apply text-[40px] mt-7;
  }

  .home-intro-head {
    @apply text-[40px];
  }

  .home-intro-sub {
    @apply text-[11px] text-center py-7;
  }

  .download-cv {
    @apply flex justify-center mt-7 h-12;
  }

  .home-marquee {
    @apply mt-16;
  }

  .home-marquee-container {
    @apply w-[80%];
  }
}

@media (max-width: 480px) {
  .home-status-container {
    @apply space-x-2 p-1 px-5 rounded-[5px] text-[12px];
  }

  .home-intro-name {
    @apply text-[35px] mt-7;
  }

  .home-intro-head {
    @apply text-[35px] text-center;
  }

  .home-intro-sub {
    @apply text-[11px] text-center py-7;
  }

  .home-marquee {
    @apply mt-12;
  }
}

@media (max-width: 320px) {
  .home-status-container {
    @apply space-x-2 p-1 px-3 rounded-[5px] text-[12px];
  }

  .home-status-circle {
    @apply w-[6px] h-[6px] mt-[6px];
    box-shadow: 1px 1px 7px #00e053, -1px -1px 7px #00ff69;
  }

  .home-intro-name {
    @apply text-[30px] mt-7;
  }

  .home-intro-head {
    @apply text-[30px] text-center;
  }

  .home-intro-sub {
    @apply text-[10px] text-justify py-7;
  }

  .download-cv {
    @apply flex justify-center mt-5 h-12;
  }

  .home-marquee {
    @apply mt-12;
  }

  .home-marquee-container {
    @apply w-[80%];
  }
}
