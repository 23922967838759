@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;800&display=swap");

.nunito-font {
  font-family: "Nunito", sans-serif;
}

.projects-container {
  @apply flex flex-col items-center mt-32 mb-20;
}

.projects-header {
  @apply nunito-font flex justify-center text-[40px] mb-12;
}

.projects-cards-grid-container {
  @apply w-[73%] grid gap-x-10 gap-y-10;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
/* bg-[#101015] */
.project-card {
  @apply h-[500px] border border-[#1d1e26] cursor-default rounded-[12px] hover:bg-[#262941] hover:scale-105 transition duration-500;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.37);
}

.project-card-small {
  @apply h-[230px] flex flex-row;
}

.grid-1 {
  grid-area: 1 / 1 / 3 / 3;
}
.grid-2 {
  grid-area: 1 / 3 / 3 / 5;
}
.grid-3 {
  grid-area: 3 / 1 / 5 / 3;
}
.grid-4 {
  grid-area: 3 / 3 / 4 / 5;
}
.grid-5 {
  grid-area: 4 / 3 / 5 / 5;
}

.project-one {
  @apply h-[65%] rounded-t-[12px] bg-[url("/public/images/project-1.png")] bg-cover bg-no-repeat;
}

.project-two {
  @apply h-[65%] rounded-t-[12px] bg-[url("/public/images/project-2.png")] bg-cover bg-no-repeat;
}

.project-three {
  @apply h-[65%] rounded-t-[12px] bg-[url("/public/images/project-5.jpg")] bg-cover bg-no-repeat;
}

.project-four {
  @apply h-[100%] w-[50%] rounded-l-[12px] bg-[url("/public/images/project-4.png")] bg-cover bg-no-repeat;
}

.project-five {
  @apply h-[100%] w-[50%] rounded-l-[12px] bg-[url("/public/images/project-3.png")] bg-cover bg-no-repeat;
}

.projects-card-content {
  @apply px-4 pt-3;
}

.small-card-content {
  @apply w-[60%] p-3 px-3;
}

.project-title-icon-wrapper {
  @apply flex flex-row space-x-4;
}

.project-title-icon-wrapper > a {
  @apply text-[20px] pt-1 hover:scale-110 hover:text-[#6e79d5] transition duration-300;
}

.project-title {
  @apply nunito-font text-[20px] font-light pb-2;
}

.project-desc {
  @apply text-[14px] font-light tracking-wide pb-2;
}

.small-card-desc {
  @apply text-[13px] font-light tracking-wide pb-5;
}

.project-stack {
  @apply flex flex-wrap space-x-4 pt-3 border-t border-[#1d1e26];
}

.small-project-stack {
  @apply flex flex-wrap space-x-2 pt-3 border-t border-[#1d1e26];
}

.project-stack-items {
  @apply flex flex-row space-x-1 pb-2  text-[13px] font-light tracking-wide;
}

.project-stack-items > img {
  @apply h-5 w-5;
}

@media (max-width: 1536px) {
  .projects-cards-grid-container {
    @apply w-[80%] grid gap-x-5 gap-y-5;
  }

  .project-card-small {
    @apply h-[245px] flex flex-row;
  }
}

@media (max-width: 1280px) {
  .projects-cards-grid-container {
    @apply w-[100%];
  }
}

@media (max-width: 1024px) {
  .project-card {
    @apply h-[450px];
  }

  .project-card-small {
    @apply h-[217px];
  }

  .project-one {
    @apply h-[60%];
  }

  .project-two {
    @apply h-[60%];
  }

  .project-three {
    @apply h-[60%];
  }

  .project-title {
    @apply text-[17px];
  }

  .project-title-icon-wrapper > a {
    @apply text-[17px] pt-[2px];
  }

  .project-desc {
    @apply text-[12px];
  }

  .small-card-content {
    @apply w-[50%] p-0 pt-4 px-0 pl-2 pr-4;
  }

  .small-card-desc {
    @apply text-[10px] text-justify pb-2;
  }

  .small-project-stack {
    @apply space-x-0;
  }

  .small-project-stack > .project-stack-items > img {
    @apply hidden pt-2;
  }

  .project-stack-items {
    @apply text-[10px] pb-2 font-light tracking-wide;
  }
}

@media (max-width: 768px) {
  .projects-cards-grid-container {
    @apply gap-x-0 gap-y-5;
  }

  .projects-header {
    @apply text-[35px];
  }

  .project-card {
    @apply project-card-small flex flex-row;
  }

  .grid-1 {
    grid-area: 1 / 1 / 3 / 5;
  }
  .grid-2 {
    grid-area: 3 / 1 / 5 / 5;
  }
  .grid-3 {
    grid-area: 5 / 1 / 7 / 5;
  }
  .grid-4 {
    grid-area: 7 / 1 / 9 / 5;
  }
  .grid-5 {
    grid-area: 9 / 1 / 11 / 5;
  }

  .project-one {
    @apply h-[100%] w-[50%] rounded-t-[0px] rounded-l-[12px];
  }

  .project-two {
    @apply h-[100%] w-[50%] rounded-t-[0px] rounded-l-[12px];
  }

  .project-three {
    @apply h-[100%] w-[50%] rounded-t-[0px] rounded-l-[12px];
  }

  .projects-card-content {
    @apply small-card-content px-4 pt-3;
  }

  .project-desc {
    @apply small-card-desc;
  }

  .small-card-desc {
    @apply text-[13px] pb-3;
  }

  .project-stack {
    @apply small-project-stack;
  }

  .small-project-stack {
    @apply space-x-2 pt-5;
  }

  .small-project-stack > .project-stack-items > img {
    @apply block pt-0 h-3 w-3;
  }
}

@media (max-width: 565px) {
  .small-card-desc {
    @apply text-[11px] pb-3;
  }
}

@media (max-width: 480px) {
  .projects-header {
    @apply text-[30px];
  }

  .projects-cards-grid-container {
    @apply gap-y-3;
  }

  .project-card-small {
    @apply h-[190px];
  }

  .project-one {
    @apply w-[40%];
  }

  .project-two {
    @apply w-[40%];
  }

  .project-three {
    @apply w-[40%];
  }

  .project-four {
    @apply w-[40%];
  }

  .project-five {
    @apply w-[40%];
  }

  .project-title {
    @apply text-[15px];
  }

  .project-title-icon-wrapper > a {
    @apply text-[15px] pt-[2px];
  }

  .projects-card-content {
    @apply px-0 pl-5 pr-0 pt-3;
  }

  .small-card-desc {
    @apply text-[10px] pb-3;
  }

  .small-project-stack {
    @apply space-x-1 pt-3;
  }
}

@media (max-width: 457px) {
  .project-card-small {
    @apply h-[210px];
  }
}

@media (max-width: 410px) {
  .project-card-small {
    @apply h-[200px];
  }

  .projects-card-content {
    @apply px-0 pl-5 pr-0 w-[55%] pt-3;
  }

  .small-card-content {
    @apply w-[59%];
  }

  .small-card-desc {
    @apply text-[10px] pb-3;
  }

  .project-stack-items {
    @apply text-[8px] pb-1;
  }
}

@media (max-width: 360px) {
  .projects-container {
    @apply mt-20 mb-16;
  }

  .project-card-small {
    @apply h-[300px] flex flex-col;
  }

  .project-one {
    @apply h-[65%] w-[100%] rounded-l-[0px] rounded-t-[12px];
  }

  .project-two {
    @apply h-[65%] w-[100%] rounded-l-[0px] rounded-t-[12px];
  }

  .project-three {
    @apply h-[65%] w-[100%] rounded-l-[0px] rounded-t-[12px];
  }

  .project-four {
    @apply h-[65%] w-[100%] rounded-l-[0px] rounded-t-[12px];
  }

  .project-five {
    @apply h-[65%] w-[100%] rounded-l-[0px] rounded-t-[12px];
  }

  .projects-card-content {
    @apply w-[100%] pr-6;
  }

  .small-card-content {
    @apply w-[100%] p-3 px-3;
  }
}
