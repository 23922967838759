.marquee-icons {
  @apply h-10 w-28 pr-3 mb-10;
}

.marquee-item-dot {
  @apply text-[7px] flex pt-3 pr-5 justify-center;
}

@media (max-width: 1440px) {
  .marquee-icons {
    @apply h-8 w-24 pr-2;
  }

  .marquee-item-dot {
    @apply text-[6px] flex pt-3 pr-3 justify-center;
  }
}

@media (max-width: 1200px) {
  .marquee-icons {
    @apply h-7 w-20 pr-2;
  }

  .marquee-item-dot {
    @apply text-[6px] flex pt-2 pr-3 justify-center;
  }
}

@media (max-width: 480px) {
  .marquee-icons {
    @apply h-5 w-16;
  }

  .marquee-item-dot {
    @apply text-[4px];
  }
}
