@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;800&display=swap");

.nunito-font {
  font-family: "Nunito", sans-serif;
}

.side-nav {
  @apply nunito-font fixed top-0 bg-[#262941] right-0 w-64 h-full text-white p-5 shadow-lg z-50;
  background: linear-gradient(
    135deg,
    rgba(38, 41, 65, 0.61),
    rgba(38, 41, 65, 0.07)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.37);
}

.nav-titles {
  @apply space-y-6 mt-10 ml-5 text-[20px] font-normal;
}

.header-anime {
  @apply relative tracking-[1px];
  transition: color 0.3s ease;
}

.header-anime::after {
  @apply content-[''] bg-white h-[1px] bottom-0 w-[0%] font-normal absolute left-0 duration-500;
}

.header-anime:hover {
  @apply text-white;
}

.header-anime:hover::after {
  @apply w-[100%];
}

.active .header-anime {
  @apply text-white;
}

.active .header-anime::after {
  @apply w-[100%];
}

@media (max-width: 480px) {
  .nav-titles {
    @apply space-y-5 text-[17px];
  }
}
