.home-marquee-container {
  @apply flex justify-center items-center w-[50%] overflow-hidden mx-0 my-auto;
}

.marquee-border {
  @apply border;
}

.upper-marquee {
  @apply flex flex-row space-x-10;
}

.marquee-dot {
  @apply flex justify-center items-center text-[10px];
}

@media (max-width: 1440px) {
  .home-marquee-container {
    @apply w-[45%];
  }

  .upper-marquee {
    @apply flex flex-row space-x-5;
  }

  .marquee-dot {
    @apply flex justify-center items-center text-[10px];
  }
}

@media (max-width: 1200px) {
  .home-marquee-container {
    @apply w-[60%];
  }
}

@media (max-width: 1024px) {
  .home-marquee-container {
    @apply w-[70%];
  }
}

@media (max-width: 480px) {
  .home-marquee-container {
    @apply w-[80%];
  }
}
