@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;800&display=swap");

.nunito-font {
  font-family: "Nunito", sans-serif;
}

.skills-container {
  @apply flex flex-col items-center justify-center mt-10 mb-10;
}

.skills-heading {
  @apply nunito-font flex justify-center text-[40px] mb-10;
}

.skils-section-toggle {
  @apply flex font-thin nunito-font tracking-wider space-x-20 border-b px-44 pb-1;
}

.skils-section-toggle > p {
  @apply cursor-pointer p-2;
  transition: color 0.3s, text-shadow 0.3s;
}

.skils-section-toggle > p:hover,
.active-tab {
  @apply border-b border-b-white rounded-t-[4px];
}

.skills-info {
  @apply w-full px-10;
}

.all-skills {
  @apply grid gap-4 mt-10;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.all-skills-box {
  @apply cursor-default flex items-center p-4 border border-[#1d1e26] rounded-[7px] hover:bg-[#262941] transition duration-300 hover:scale-95;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.04),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.37);
}

.skill-image {
  @apply h-9 w-9 mt-2;
}

.skill-desc {
  @apply pl-2;
}

.skill-text {
  @apply text-[17px];
}

.skill-desc-text {
  @apply font-light text-[13px];
}

@media (max-width: 1440px) {
  .skils-section-toggle {
    @apply space-x-10 px-40;
  }

  .all-skills {
    @apply gap-3;
  }

  .all-skills-box {
    @apply p-2;
  }
}

@media (max-width: 1024px) {
  .skills-heading {
    @apply text-[35px];
  }

  .skils-section-toggle {
    @apply space-x-7 px-32 text-[15px];
  }

  .skils-section-toggle > p {
    @apply whitespace-nowrap;
  }

  .all-skills {
    @apply gap-2;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  .skill-text {
    @apply text-[15px];
  }

  .skill-desc-text {
    @apply font-light text-[11px];
  }
}

@media (max-width: 850px) {
  .skills-heading {
    @apply text-[30px] mb-5;
  }

  .skils-section-toggle {
    @apply px-20 text-[13px];
  }

  .all-skills {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }

  .skill-image {
    @apply h-7 w-7 mt-1;
  }

  .skill-text {
    @apply text-[13px];
  }

  .skill-desc-text {
    @apply font-light text-[9px];
  }
}

@media (max-width: 680px) {
  .skils-section-toggle {
    @apply flex flex-wrap items-center justify-center space-x-1 border-b px-0 pb-2;
  }

  .skils-section-toggle > p {
    @apply text-[11px] pb-1;
  }

  .all-skills {
    @apply grid gap-2 mt-10;
    grid-template-columns: repeat(3, minmax(120px, 1fr));
  }

  .skill-image {
    @apply h-5 w-5 mt-2;
  }

  .skill-text {
    @apply text-[10px];
  }
}

@media (max-width: 480px) {
  .all-skills {
    @apply grid gap-1 mt-10;
    grid-template-columns: repeat(3, minmax(90px, 1fr));
  }

  .skill-desc-text {
    @apply hidden;
  }
}

@media (max-width: 320px) {
  .skill-text {
    @apply text-[10px];
  }
}
